  @import '~bootstrap/scss/bootstrap.scss';

@import 'general.scss';

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,900&display=swap');


html{
    *{
       // font-family: 'Space Grotesk', sans-serif;
       font-family: 'Poppins', sans-serif;
        font-weight: 200;        
    }

}

$amarillo:#ffdb30;
$berenjena:#6d2b5e;
$blancopalo:#f8f8f8;
$blackPard:#282828;
$blackDark:#0f0f0f;
$azulon:#4f97c1;

$coral: #f4868c;

a{
    color:$coral;
}

$sepTop:30px;

$alturaLogo:100vh; //190px;

:root{
    --alturaLogo:#{$alturaLogo};  
}

body{
    background-color:$blancopalo;
}


p{
    color:$blackDark;
    font-weight: 300;
}

.texto_logo{
    text-transform: uppercase;
    line-height: 1;
    margin-top:10px;
    font-size: 0.9rem;
}
.cont_log_menu{
    position: fixed;
     top: $sepTop; 

     max-width:300px;

  //  display:none !important;

    @include media-breakpoint-down(md){
        max-width:150px;
        left:15px;
     //   display:flex !important;
    }

    @include media-breakpoint-down(xl){
        max-width:200px;
        left:15px;
     //   display:flex !important;
    }


}

.logo_back{

    max-width:300px;

    @include media-breakpoint-down(md){
        max-width:150px;
     //   display:flex !important;
    }
    @include media-breakpoint-down(xl){
        max-width:200px; 
     //   display:flex !important;
    }
}

.txt_claim{
    @include media-breakpoint-down(md){
        max-width:105px;
     //   display:flex !important;
    }
    @include media-breakpoint-down(xl){
        max-width:140px;
     //   display:flex !important;
    }
}

.sep_top{
    padding-top:$sepTop;
}


.sep_top_max{
    //padding-top:(--#{$alturaLogo});
   // padding-top:var(--alturaLogo);
   height:var(--alturaLogo);
}

.reset_ul{
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
    list-style-position: inside;
}


.container{
  
    @include media-breakpoint-up(md){
        // padding-right: 30px !important;
        // padding-left: 30px !important;
    }

}
h1, h2, h3, h4, h5, h6{
    font-weight: 800;;
    color:$blackPard;
    margin-bottom: 30px;;
    font-family: 'Poppins', sans-serif;

}

.cont_sec{
    @include media-breakpoint-up(md){
        max-width: 90%;

        font-size: 1.1rem;;
        *{
            line-height: 1.6;
        }
        

    }

    @include media-breakpoint-up(lg){
        max-width: 80%;;
    }
}

.clip-img {
    clip-path: url(#clip_logo);
  }

  .img_wrap{
      width:100%;
      height:auto;
      height: auto;
      border:2px solid red;

        position: absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;

        z-index:10;

  }

  .img_mask{
      position: absolute;
      top:0;
      left:0;
      z-index: 10;;
  }

  .seccion{
      padding-bottom:75px;
    @include media-breakpoint-up(md)     {
      padding-bottom:100px;
      min-height: 100vh;

    }

  }

.seccion-40{
    @include media-breakpoint-up(md){
    min-height: 40vh !important; 
    } 
}

.seccion-50{
    @include media-breakpoint-up(md){
    min-height: 50vh !important; 
    } 
}

.seccion-60{
    @include media-breakpoint-up(md){
    min-height: 60vh !important;

    }

}


.seccion-65{
    @include media-breakpoint-up(md){
    min-height: 65vh !important;

    }

}

.seccion-75{
    @include media-breakpoint-up(md){
    min-height: 75vh !important;

    }

}
  
  .intro-overlay{

    height: 100vh;
    width: 100vw;
    // border:3px solid orange;
  }

  .intro-canvas{
      position: fixed;
      pointer-events: none;
      width:100vw;
      height: 100vh;
      top:0;
      left:0;       
      background-color: transparent;
      
      z-index: -1;  
  }

  canvas{
      top:0;
      left:0; 
      width:100vw;
      height: 100vh; 
    //   border:1px solid blue;
  }

  .tit_sec{
      font-size: 2rem;;
      font-weight:800;
      position: relative;
      span{
        font-size: 2rem;;
        font-weight:800;
      }

        @include media-breakpoint-up(md){
            font-size: 2.2rem;; 
            span{
                font-size: 2.2rem;; 
            }
        }


        @include media-breakpoint-down(md){
            &:after{
                content:"";
                position: absolute;
                background-color: $blackPard;
                width:40px;
                bottom:-12px;
                left:0;
                height:3px;
            }

            &.no_guion{
                &::after{
                    display: none;
                }
            }
        }



  }

  .lema{
      position: absolute;
      top:50vh;
      transform: translateY(-50%);
 


     
  }

  section{
    //  border:2px solid blue;
  }

  .menu_fixed{
      position:fixed;
      
      display: flex;
      flex-direction: row;
      padding:10px;
      top:50%;
      transform-origin: right bottom;
      transform:  rotate(-90deg) translate(50%, -50%) ;
      right:0;
      
      li{
         
          border-right: 1px solid $blackPard;
          a{
              color: $blackPard;
              padding:0 20px;
              font-size: 0.9rem;
              text-transform: uppercase;
              text-decoration: none;
              line-height: 1;
          }

          &:last-child{
              border:0 !important; 
          }
      }
  }
 

.img_mask{ 
}

  #img_code, #img_puntos, #img_binary, #img_neo, #img_binary{ 
      transition: all 0.5s;
      opacity: 0;
  }

  .img_wrap{
    transition: opacity 0.5s;
  }

  .presentacion{

    #img_malla{
        opacity: 0;
    }

    #img_code{
        
        opacity: 1 !important;
    }
  }


  .logo_home{

    #img_malla{
        opacity: 0;
    }

    #img_malla{
        
        opacity: 1 !important;
    }
  }


  .logo_presentacion{

    #img_malla{
        opacity: 0;
    }

    #img_code{
        
        opacity: 1 !important;
    }
  }

  .logo_participa{

    #img_malla{
        opacity: 0;
    }

    #img_puntos{
        
        opacity: 1 !important;
    }
  }

  .logo_sede{

    #img_malla{
        opacity: 0;
    }

    #img_binary{
        
        opacity: 1 !important;
    }
  }

  .logo_organiza{
    #img_malla{
        opacity: 0;
    }

    #img_neo{
        
        opacity: 1 !important;
    }
  }

  
  .logo_recursos{
    #img_malla{
        opacity: 0;
    }

    #img_deg{
        
        opacity: 1 !important;
    }
  }

    
  .logo_preparacion{
    #img_malla{
        opacity: 0;
    }

    #img_prep{
        
        opacity: 1 !important;
    }
  }


  .logo_sede{

    #img_malla{
        opacity: 0;
    }

    #img_sede{
        
        opacity: 1 !important;
    }
    
  }

  .gradient_top{
    background: rgb(255,255,255);
  //background: linear-gradient(320deg, rgba(255, 255, 255, 0) 0%, rgba(255,255,255,1) 67%); 

    position:fixed;
    z-index:100;
    pointer-events:none;
    top:0;
    bottom:0;
    left:0;
    right:0;
    
    opacity: 0.8;
    
    @include media-breakpoint-up(md){
        opacity: 0.55;
    }
   /* display: none;*/
  }

  .flujo{
      position: absolute;
      right:0;
      top:0;
      transform: translate(25%, -30%);
     
      max-width: 450px;;
      height: auto;
      opacity: 0.7;
  }

  .cont_flujo{
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    left:0; 
    z-index: -11;
  }

  .subtit{
      font-weight: 600;
      color:$azulon;

        font-size:1rem;

        @include media-breakpoint-up(md){
              font-size:1.2rem;            
        }

      
  }

  .cont_sec > .subtit{
        margin-top: -10px;;
  }

  .laptops{
      padding-left: 50px;
      padding-right:0;
      @include media-breakpoint-up(md){
        padding-left: 100px;
      padding-right:0;        
      }
      width:100%;
      max-width: 500px;;
      margin-top: 30px;
      z-index: -1;
  }

  .pieza{
      position: absolute;
      left:-15px;
      top:50%;
      transform: translate( -100%  , -50%);
      z-index: 100;;
  }


  .cont_logo{
      z-index: 10000;;
  }

  .circulines{
      position: absolute;
      bottom:0;
      left:-20px;
      transform: translate(-100%, 50%);
  }
  .lema_inicial{
      position: relative; 

      margin-top:40vh;

      @include media-breakpoint-up(md){
         margin-top:50vh;

      }

      transform: translateY(-50%);
      font-size:3rem;
      *{
          font-size:3rem;
      }
  }

  .pieza_zero{
      top:0;
      transform: translate( -100%  , -25%);

      
  }

  .linea{
    position: absolute;
    left:50%;
    top:15px;
    z-index: -10;
    height:calc(180vh + 15px);
    transform: translateX(-50%);
    width:5px;
    background-color: black;
}

.linea_select{



    @include media-breakpoint-down(sm){
        margin-top:30px;
            display:flex; 
            justify-content:center;
            .link_sede{
                margin-right:5px;
                margin-left:5px;
            }
    }


    @include media-breakpoint-up(sm){

        width:70%;
        height:170px;
        border:5px solid black;

        border-top:0;
        border-right:0;
    
        z-index: 1;
        position: relative;
        transform: translate(-80px, -100px);

    }

}


.vuelve_linea{

    @include media-breakpoint-up(sm){

             border:5px solid black;
             border-top:0;

             border-left:0;
              height:60px;
              transform: translate(-78px, 8px);
            // border-top: 0;
            // border-left: 0;
            // z-index: 0;        
    }

    @include media-breakpoint-up(md){
        height:120px;
        transform: translate(-75px, -20px);
        border:5px solid black;
        border-top: 0;
        border-left: 0;
        z-index: 0;    
    }


    

}

.link_sede{

    padding:15px;

    @include media-breakpoint-up(sm){
   
        border: 5px solid black;;
        display: inline-block;;
        text-decoration: none; 
    }

    font-weight: 500;


    span{
        font-weight: 500; 
    }

    text-decoration: none !important;

    font-size: 1rem;;

    color:black;
    text-transform: uppercase;

    @include media-breakpoint-down(sm){
        
        font-size: 1.3rem;;
        padding:10px 20px ;

        display: inline-block;

        *{
        color:$coral !important;     
        }
        span{
            color:$coral !important;     
        }

        &:hover{
            background-color: $coral !important;
            color:white !important;

            span{
                color:white !important;
            }
        }

        border:3px solid $coral;

        border-radius: 500px;;
        margin:0 20px !important;

        &:first-child{
            position: relative;
            &::after{
                display: none;
            }
            // &:before{
            //     content:"";
            //     position: absolute;
            //     top:0;
            //     bottom:0;
            //     right:-5px;
            //     width:5px;
            //     transform-origin: center;
            //     transform: rotate(15deg);
            //     background-color: $coral;

            // }
        }


    }




    @include media-breakpoint-up(sm){

            font-size: 1.5rem;;

            position: absolute;
            bottom:0;
            transform: translate(-75% ,  150%);
            left:50%;  
            
             
            background-color: $coral;
            color:white;

            &:hover{
                background-color:$black;
                color:$coral;
            }
    }




    transition:all 0.3s;



    &:after{
        position: absolute;
        width:5px;
        height:60%;
        top:-3px;
        left:50%;
        content:"";
        background-color: black;
        
        transform: translate(-50%, -100%);
    }
}

.link_sede_1{

    @include media-breakpoint-up(sm){
    
        &:before{
            position: absolute;
            width:5px;
            height:65px;
            bottom:0;
            left:50%;
            content:"";
            background-color: black;
            transform: translate(-50%,  100%);
        }
    }

    @include media-breakpoint-up(md){
    
        &:before{
            height:92px;
        }

    }


}

.link_sede_2{
    @include media-breakpoint-up(sm){
        right:0;
        left:auto;
        transform: translate(50% ,  150%);
    }
}

.linea_up{
    display:none;
    @include media-breakpoint-up(sm){
        display:flex;
        position: absolute;
        left:50%;
        top:15px;
        z-index: -10;
        // height:calc(200vh + 15px);
        height:292px;
        transform: translateY(-100%);
        width:5px;
        background-color: black;
    }
}

.row_logos{
    margin-bottom:50px;
}
.link_logo{
 
    &:last-child{
        margin-right: 0;
    }
    .logo_uv{
        max-height: 65px;

    }
    .logo_etseuv{
        max-height: 65px;

    }

    @include media-breakpoint-down(md){
        margin-bottom:30px;
        display:block;

        img{
            max-width:150px;
        }
    }
}

.logo_eps{
    max-height: 120px;
}

.logo_ua{
    max-height: 120px;
}

.link_lang{
   position: fixed;
   top:22px;
   right:40px;
   a{
       font-size: 0.8rem;
       color:black;
       text-decoration: none;
   }
}

.gran_lema{
        transform: translateY(-10px);
        -webkit-text-stroke: 1.5px $blackPard ; 
        color:transparent;
        font-size: 2.3rem;
        margin-bottom: 0;

        @include media-breakpoint-down(md){
            text-align:center; 
            width:100%;
        }


        *{
            font-size: 2.3rem;
        }
        @include media-breakpoint-up(xl){
            font-size: 2.5rem;
            *{
                font-size: 2.5rem;
            }
        }
        @include media-breakpoint-up(xl){
            font-size: 3rem;
            *{
                font-size: 3rem;
            }
        }

         
}


.mini_lema{

    @include media-breakpoint-down(md){
        text-align:center;
        padding-left:30px;
        padding-right:30px;
    }

    font-size: 1.2rem;
    *{
        font-size: 1.2rem;
    }
    @include media-breakpoint-up(xl){
        font-size: 1.3rem;
        *{
            font-size: 1.3rem;
        }
    }
    @include media-breakpoint-up(xl){
        font-size: 1.5rem;
        *{
            font-size: 1.5rem;
        }
    }
    text-transform: none !important;
}

.circulines{
    position: absolute;
    bottom:0;
    left:-20px;
    transform: translate(-100%, 70%);
    z-index:1000;
}

.quieres{
    color:$berenjena;
    font-weight: 500;
    font-size:1.3rem;

}


.aros{

    display:none;


    max-width:300px;

    @include media-breakpoint-up(md){

       
        position:absolute;
        top:0;
        right:0;
        transform:translate(75%, -25%);
        opacity:0.75;  
        
        display:flex;
    }

    @include media-breakpoint-up(xl){
        max-width:450px;
    }

    

}


.fondo_lap{
    width:50vw;
    height:50%;
    position:absolute;
    top:0;
    left:50%;
    background:url('../images/cruz.svg'); 
    z-index:0;

    display:none;

    // @include media-breakpoint-up(lg){
    //     display:flex;
    // }
 
}
.pos_rel{
    position:relative
}

body { 
}

.lema_inicial{
    display: inline-block; 
    @include media-breakpoint-down(md){
        width:100%;
    }
}

footer{
    background-color: $blackPard;
    padding-top:30px;
    padding-bottom:30px;
    position: relative;




}


// main{
//     &:before{ 
//         content:"";
//         background-color: $blackPard;
//         position: absolute;
//         width:100%;
        
//         height:90px;

//         bottom:0;
//         z-index: 0; 
// }

// }

.logo_oi{
    max-width:75px;
}



.menu_prin{

    
    display:inline-block !important; 
    

    right:30px;


            @include media-breakpoint-down(xl){
                justify-content:end;
                text-align:right; 
            }


    
            .bot_menu{

                display:inline-block;
                padding:5px;

                width:40px;
                height:22px;
            
                .rect_menu{
                    transition:all 0.3s;
                    width:30px;
                    height:2px;
                    background-color:$blackPard;

                    margin-bottom:3px;

                    &:last-child{
                        margin-bottom:0;
                    }
                }

                margin-top:3px;

                // @include media-breakpoint-up(xl){
                //     display:none;
                // }

            }


    z-index:20000;
    top:25px;
    position:fixed; 
    .in_menu{

        transform:translateX(150%);
        transition:all 0.3s;

           // @include media-breakpoint-down(xl){
                width:100vw;
                height:100vh;
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;
                top:0;
                bottom:0;
                position:fixed;
            //}


     


            // @include media-breakpoint-up(xl){
            //     display:flex; 
            //     transform:translateX(0);
            //     transition:all 0.3s; 
            // }

        
        li{
            //margin-left:10px;
            text-align: center;
            
            padding-top:7px;
            padding-bottom: 7px; 
            a{
                text-decoration: none;
                padding-left:10px;
                
                color:$blackPard;
                text-transform: uppercase;
                //border-left:1px solid $blackPard;
                
                transition:all 0.3s;
                line-height:1;


                 span{
                    font-size: 1.2rem;;
                    font-weight:700;
                }

                &:hover{
                    color:$azulon;
                }
            }

            &:first-child{
                a{
                    border:0;
                }  
            }


        }
    }
}

.sec_elige_sede{

}

.seccion_home{
    @include media-breakpoint-down(md){
        max-height:75vh;
    }
}

.menu_abierto{

    .bot_menu{
        z-index:1000;
        position:fixed;
        right:30px;


    }

    .in_menu{ 
        display:flex;


        //@include media-breakpoint-down(xl){

            transform:translateX(0);
            position:fixed;
            top:0;
            left:0;
            right:0;
            bottom:0;
            height:100vh;
            z-index:10;
            background-color:rgba(255,255,255,0.95);
            display:flex;
            flex-direction:column;
            align-items:center;
            justify-content:center;

            li{
                z-index:0;
                border:0;
                a{
                    border:0;
                    font-size:1.5rem;
                } 

                padding-top:7px;
                padding-bottom:7px;
            }
        //}
    }

 

    .rect_menu{ 

        position:absolute;

        top:50%;
        left:50%;

        transform:translate(-50%, -50%) rotate(30deg);

        &:nth-child(2){
            transform:translate(-50%, -50%) rotate(-30deg);
        }

        &:last-child{
            display:none;
        }

    }

}

.container{
    justify-content:center; 
}



// body{overflow-x:hidden}

footer{
    .container{
 
    }
}

.main{
    overflow:hidden;
    max-width:100vw;
    min-height:100vh;
}

.aros{
    display:none;
}

footer{ 
}


.caja_box{
    border:2px solid red;
    padding:25px;
    position:fixed;
    right:0;
    display:inline-block;
}

.main{ 
}
body{ 
}

.fondo_bolas{
    position: fixed;
    z-index:-1; 
    width:100vw;
    height:100vh;

    canvas{
        width:100%;
        height:100%;
    }
}


.mail_to{
    color:$blancopalo;
    text-decoration:none;
}

.flex_mails{
    position:relative;
    align-items: stretch;

    .sep_mail{
        height:20px;
        width:1px;
        background-color:$blancopalo;
        margin:0 15px;
        position:relative;
    }
    

    @include media-breakpoint-down(md){

        flex-direction:column;

        .sep_mail{
            height:1px;
            width:20px;
            background-color:$blancopalo;
            margin: 10px 0;
            position:relative;
        }
        
    }
}


.col_logos{
    padding-left:10px;
    padding-right:10px;
    @include media-breakpoint-up(md){
        padding-left:35px;
        padding-right:35px;
    }

}

.mt-logo{
    margin-top:35px;
}

.language_cas{
    .lang_val{
        display: none;
    }

}

.language_val{
    .lang_es{
        display: none;
    }
}

.link_inside{

    color:rgb(244, 67, 54);
    text-decoration: none;

    &:hover{
        color:#4f97c1;
    }

}

.sep_top{
    margin-top:50px;
}

.logo_leagues{
    max-width:250px;
}
.logo_apicv{
    max-width:250px;
}


.subdesc{
    margin-top: 30px;
}

.tit_acordeon{

    text-decoration: none;
    position: relative;
    display: block;

    padding-bottom: 20px;
    font-size: 1.3rem;
    font-weight: 500;
    color:#e91e63 !important;

    &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #e91e63;
        bottom: 10px;
        left:0;
    }

    &:after{
        content: "";
        position: absolute;
        width:20px;
        height:20px;
        background-position: center;
        background-size: contain;
        background-image: url('../images/plus.svg');

        top:calc(50% - 8px);
        right: 0;
        transform: translateY(-50%);

    }

    &.cruz{


        &:after{
             
            transform: translateY(-50%) rotate(-45deg);
    
        }

    }
}

.expli_acordeon{
    display: none;

    *{
        color:$blackPard;
    }

    >h4{
        margin-top: 10px;
        font-weight: 400;
        font-size: 1.2rem;;

    }

    ul{
        margin-left: -10px;
        margin-bottom: 30px;
    }

    li{
        margin-bottom: 10px;;
    }
}

.coloca_trapecios{
    position: absolute;
    top:0;
    bottom: 0;
    width: 100%;
    //background-color: rgba(244, 67, 54, 0.5);
    pointer-events: none;
 
    z-index:1000;
}

#trapecio_alfa{
    opacity: 0;
}

.trap_help{
    position: absolute;

    left: -15px; 
    transform: translateX(-100%); 
    opacity: 1;
}

.trapecio_ref{
     opacity: 0;
}

b, strong{
    font-weight: 500;
}

.col_ejercicios{
    li{
       // margin-bottom: 10px;;
    }
    a{
        text-decoration: none;
        color:$coral;

    }
}