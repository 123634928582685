:root {
    --height-sep_header: 100px;
    --height-sep_header_md: 200px;
  }
  
  
  
  ::-moz-selection {
    color: rgb(0, 0, 0);
    background: rgb(244, 67, 54);
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
  
  ::selection {
    color: rgb(0, 0, 0);
    background: rgb(244, 67, 54);
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
  
  ::-webkit-scrollbar {
    width: 7px;
    background-color: #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #282828;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }

.flex-col{
    flex-direction: column;
} 

.pos-rel{
    position: relative;
}
.op_0{
  opacity: 0;
}